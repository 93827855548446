import 'normalize.css';
import './index.scss';

import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import NavContext, { NavProvider } from '../../components/Layout/Nav/NavProvider';

import SectionHeader from '../../components/SectionHeader';
import { getCurrentLanguage } from "../../i18n";
import DataPanel from '../../components/DataPanel';
import DataPanelItem from '../../components/DataPanel/DataPanelItem';
import LegalPage from '../../components/LegalPage';
import { t } from 'i18next';

const PageTemplate: FC = () => {
  const page: any = {}
  page.i18n_lang = getCurrentLanguage();
  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const section = useRef<HTMLDivElement>();
  const slug = typeof window !== 'undefined' ? window.location.pathname : '';


  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  return <NavProvider>
  <Layout
    language={page.i18n_lang}
    isHome={false}
  >
    <SEO
      title={t('dataProtection.pageTitle')}
      description={t('dataProtection.description')}
      lang={page.i18n_lang}
      slug={slug}
    />
    <SectionHeader
      h1={t('dataProtection.sectionHeaderTitle')}
      inView={true}
      isTop 
    />

    <LegalPage>
      <div className="legal__table" ref={section}>
        <DataPanel>
          <DataPanelItem title={t('dataProtection.dataPanelItemResponsible')} alignedToRight>
            {t('dataProtection.dataPanelItemResponsibleContent')} 
          </DataPanelItem>
          <DataPanelItem title={t('dataProtection.dataPanelItemPurposesAndLegitimation')}>
            <div>
              <p>{t('dataProtection.dataPanelItemPurposesAndLegitimation')}</p>
              <hr />
              <ol>
                <li>
                  <span className="list-separator"></span>
                  <div>
                    <p>{t('dataProtection.dataPanelItemContractualOrOrganicPurposes')}</p>
                    <ol>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemWebsiteUsageAndNavigation')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemManageUserCondition')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemCommunicateBenefitsAndAdvantages')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemProvideContractedServices')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemReceiveRequestedInformation')}</p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li>
                  <span className="list-separator"></span>
                  <div>
                    <p>{t('dataProtection.dataPanelItemInterestBasedPurposes')}</p>
                    <ol>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemOfferOrRecommendServicesOrProducts')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemElaborateCommercialProfile')}</p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li>
                  <span className="list-separator"></span>
                  <div>
                    <p>{t('dataProtection.dataPanelItemExplicitConsentPurposes')}</p>
                    <ol>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemOfferOrRecommendServicesOrProductsAfterUnsubscribe')}</p>
                        </div>
                      </li>
                      <li>
                        <span className="list-separator"></span>
                        <div>
                          <p>{t('dataProtection.dataPanelItemOfferOrRecommendServicesOrProductsOfThirdParties')}</p>
                        </div>
                      </li>
                    </ol>
                    <hr />
                    <p>{t('dataProtection.dataPanelItemInfoSendingMediums')}</p>
                  </div>
                </li>
              </ol>
            </div>
          </DataPanelItem>
          <DataPanelItem title={t('dataProtection.dataPanelItemRecipients')} alignedToRight>
            {t('dataProtection.dataPanelItemRecipientDetails')}
          </DataPanelItem>
          <DataPanelItem title={t('dataProtection.dataPanelItemRights')} alignedToRight>
            {t('dataProtection.dataPanelItemRightsDescription')}
          </DataPanelItem>
          <DataPanelItem title={t('dataProtection.dataPanelItemAdditionalInfo')} alignedToRight>
            {t('dataProtection.dataPanelItemAdditionalInfoDescription')}
          </DataPanelItem>
        </DataPanel>
      </div>
    </LegalPage>
  </Layout>
</NavProvider>

}

export default PageTemplate 
