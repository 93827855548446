import "./LegalPage.scss";

import React, { FC, useContext, useEffect, useState } from "react";
import Slider from "../Slider";
import HomeSliderContact from "../HomeSliderContact";
import NavContext from "../../components/Layout/Nav/NavProvider";

const LegalPage: FC = ({ children }) => {
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);
  const { isMobile } = useContext(NavContext);
 
  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile]);

  function pageContent() {
    const elements: JSX.Element[] = [
      <div className="legal-container scrollable">{children}</div>,
      <HomeSliderContact />,
    ];
    return elements.map((element, index) => (
      <span key={index}>
        {element}
      </span>
    ));
  }

  return (
    <section className="legal">
      {isMobileActive ? pageContent() : <Slider>{pageContent()}</Slider>}
    </section>
  );
};

export default LegalPage;
